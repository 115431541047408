import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import SEO from '../components/YoastSEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
  text-align: center;
`;

function NotFound({ data }) {
  const { t } = useTranslation();

  const {
    site: {
      siteMetadata: { title: fallbackTitle },
    },
    wp: {
      seo: {
        schema: { siteName },
        breadcrumbs: { notFoundText: siteTitle },
      },
    },
  } = data;

  const withHero = true;

  return (
    <Layout>
      <SEO
        title={siteTitle || `404: Not found - ${siteName || fallbackTitle}`}
      />
      {withHero ? (
        <Hero
          heading={t('404title')}
          pageTitle
          // actions="Actions"
          tint
          big="big"
        >
          <p>{t('404text')}</p>
        </Hero>
      ) : (
        <Container>
          <Row>
            <Col>
              <h1>{t('404title')}</h1>
              <p>{t('404text')}</p>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
}

NotFound.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query NotFound {
    site {
      siteMetadata {
        title
      }
    }
    wp {
      seo {
        schema {
          siteName
        }
        breadcrumbs {
          notFoundText
        }
      }
    }
  }
`;

export default NotFound;
