import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { getPathPrefix } from '../../utils/i18n';

function YoastSEO({ children, title: titleProp, post }) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          i18n {
            defaultLang
          }
        }
      }
    }
  `);

  const {
    yoast_meta: meta = [],
    yoast_title: yoastTitle,
    yoast_json_ld: jsonLd,
    wpml_current_locale: lang,
    wpml_translations: translations,
    is_page_on_front: isFrontPage,
  } = post;
  const title = titleProp || yoastTitle;

  const filteredMeta = meta
    .filter((tag) => tag.name !== 'robots')
    // Remove null attributes
    .map((atts) =>
      Object.keys(atts)
        .filter((k) => atts[k] !== null)
        .reduce((newObj, k) => ({ [k]: atts[k], ...newObj }), {}),
    );

  return (
    <Helmet
      meta={filteredMeta}
      htmlAttributes={{
        lang: lang || siteMetadata.i18n.defaultLang,
      }}
    >
      {title && <title>{title}</title>}

      {translations?.map?.((translation) => (
        <link
          key={translation.locale}
          rel="alternate"
          hrefLang={translation.locale}
          href={
            isFrontPage ? getPathPrefix(translation.locale) : translation.link
          }
        />
      ))}

      {jsonLd && <script type="application/ld+json">{jsonLd}</script>}

      {children}
    </Helmet>
  );
}

YoastSEO.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  post: PropTypes.shape({
    yoast_title: PropTypes.string,
    yoast_json_ld: PropTypes.string,
    yoast_meta: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        property: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
    wpml_current_locale: PropTypes.string,
    wpml_translations: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        locale: PropTypes.string,
      }),
    ),
    is_page_on_front: PropTypes.bool,
  }),
};

YoastSEO.defaultProps = {
  children: null,
  title: '',
  post: {},
};

export default YoastSEO;
